import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'
import { useIntegrationStore } from "../../store/IntegrationStore.js"
import { storeToRefs } from 'pinia';

const CampaignSitesRow = {
    name: "CampaignSitesRow",
    data() {
        return {
            store: storeToRefs(useIntegrationStore()),
            campaignSelected: false,
            selectedSites: [],
            openDirectionThresholdIndex: 3, // Direction will be different for these number of rows at the top
            isPreSelectSitesUpdated: false,
            isReady: {
                campaign: false,
                sitesOptions: false
            }
        }
    },
    methods: {
        constructSelectedDetail() {
            // Map campaignSelected
            return {
                campaignId: this.campaign.id,
                siteIds: [...this.selectedSites],
                disabled: this.campaign.disabled || false
            }
        },
        onCheckboxChange(newValue){
            this.campaignSelected = newValue
        },
        // Update if the sites is connected with the ads based on `connectionDetails`
        updatePreSelectedSites() {
            let matchingSiteObjects
            // If ad did not connect to any campaign before
            if (!this.connectionDetails || !this.connectionDetails.length) {
                matchingSiteObjects = this.filterByDefaultSelections(this.sitesOptions);

            } else {
                // Base on `connectionDetails` to pre-select previous connected sites
                matchingSiteObjects = this.filterByConnectionDetails(this.sitesOptions);
            }
            // Check if the selected site exists in `selectedSites`, remove them before pushing
            if (matchingSiteObjects) {
                this.selectedSites = matchingSiteObjects;
                this.$nextTick(() => {
                    this.isPreSelectSitesUpdated = true
                })

                if (this.isLastCampaign) { // Update store to hide loading overlay in CM360.js
                    this.store.updateIsLastSiteSelectionReady(true);
                }
            }
        },
        filterByDefaultSelections(siteOptions) {
            const parsedDefaultSiteSelections = JSON.parse(JSON.stringify(this.store.getDefaultSiteSelections));
            return siteOptions.filter(siteOption => parsedDefaultSiteSelections.includes(siteOption.value));
        },
        filterByConnectionDetails(siteOptions) {
            const connectedSiteIds = this.connectionDetails.map(item => item.site_id);
            return siteOptions.filter(siteOption => connectedSiteIds.includes(siteOption.value));
        },
        shortenOptionName(optionValue) {
            return optionValue.substring(0, 18) + '...';
        }
    },
    watch: {
        campaignSelected(newValue, oldValue) {
            // Update parent selected array
            this.$emit("updateSelected", this.constructSelectedDetail(), newValue, this.campaign.id)

            if(this.isCampaignAndSitesOptionsReady){
                this.updatePreSelectedSites()
            }
        },
        selectedSites: {
            deep: true,
            handler(newValue, oldValue) {
                this.$emit("updateSelected", this.constructSelectedDetail(), this.campaignSelected)

                // Prevent update the store when we update selectedSites by store.defaultSiteSelections on init
                if(this.isPreSelectSitesUpdated && this.isCampaignAndSitesOptionsReady) {
                    // Update store with selected site id array
                    const newValues = newValue.map(item => item.value);
                    this.store.updateDefaultSiteSelections(newValues);
                }
            }
        },
        campaign: {
            deep: true,
            immediate: true,
            handler(newValue, oldValue) {
                this.campaignSelected = newValue.checked
                this.isReady.campaign = true
            }
        },
        sitesOptions: {
            deep: true,
            immediate: true,
            handler(newValue, oldValue) {
                if(newValue.length) {
                    this.isReady.sitesOptions = true
                }
            }
        },
        // First init call, this should only called once
        isCampaignAndSitesOptionsReady: {
            immediate: true,
            handler(newValue, oldValue) {
               if(newValue === true) this.updatePreSelectedSites()
            }
        },
    },
    computed: {
        multiSelectOpenDirection() {
            return this.idx < this.openDirectionThresholdIndex ? 'bottom' : 'top';
        },
        isCampaignAndSitesOptionsReady() {
            return Object.values(this.isReady).every(value => value === true);
        }
    },
    components: {
        Multiselect,
    },
    props: {
        campaign: Object,
        sitesOptions: Array,
        valueField: String,
        idx: Number,
        isLastCampaign: Boolean,
        connectionDetails: Array
    },
    template: `
    <div class="campaign-sites-row d-flex justify-content-between py-3 mb-2" :class="{'selected' : campaignSelected}">
        <b-form-checkbox
        class="ml-4 mb-0 col-6 text-break"
        stacked
        v-model="campaignSelected"
        aria-describedby="campaign sites connection row"
        inline
        @change="onCheckboxChange"
        >
            <p :id="'campaign-name-' +  campaign.id" class="font-weight-normal d-inline m-0" :class="{'disabled-text' : campaign.disabled }">
                <font-awesome-icon v-if="campaign.disabled" class="text-warning" :icon="['fas', 'triangle-exclamation']" />
                {{ campaign.name }}
            </p>
            <b-tooltip v-if="campaign.disabled" class="red-tooltip" :target="'campaign-name-' +  campaign.id" triggers="hover">
                Campaign unavailable
            </b-tooltip>
        </b-form-checkbox>

        <Multiselect
        v-if="campaignSelected"
        class="col-5 sites-multiselect text-dark"
        mode="tags"
        v-model="selectedSites"
        placeholder="Select sites"
        :openDirection="multiSelectOpenDirection"
        :close-on-select="false"
        :searchable="true"
        :trackBy="['value','label']"
        :object="true"
        :options="sitesOptions"
        :disabled="campaign.disabled">
            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <span class="multiselect-tag" v-b-tooltip.hover :title="option.label">
                    {{ shortenOptionName(option.label) }}
                    <span
                    v-if="!disabled"
                    class="multiselect-tag-remove"
                    @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                        <span class="multiselect-tag-remove-icon"></span>
                    </span>
                </span>
            </template>
        </Multiselect>
    </div>
    `,
}

export { CampaignSitesRow }

